import React, { useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, InputNumber, AutoComplete, message } from "antd"
import { isEmpty, isEqual } from "lodash"
import { DatePicker, SupplySelect, TaskForm, Upload, UserSelect } from ".."
import { useAuth, useTask } from "~/hooks"
import CustomEditor from "../Editor"
import { PRODUCT_UNIT } from "~/constants"
import { MinusCircleOutlined } from "@ant-design/icons"
import { last } from "lodash"
import dayjs from "dayjs"

const StageTaskForm = ({
  employeeMode = false,
  loading,
  initialValues,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const { user } = useAuth()
  const isInCharge = user?.role === "employee" && user?.id === initialValues?.assignedTo?.value
  const [form] = Form.useForm()
  const watchStageTaskSupplies = Form.useWatch("stageTaskSupplies", form)

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { stageTaskSupplies, assignedTo, task, ...rest } = values

    onSubmit({
      ...rest,
      assignedToId: assignedTo?.value,
      stageTaskSupplies: stageTaskSupplies?.map((tS) => ({
        supplyId: tS.supply?.value,
        quantity: tS.quantity,
        unit: tS.unit,
        price: tS.price,
        productAmount: tS.productAmount,
        note: tS.note,
      })),
    })
  }

  const {
    show: showTaskDrawer,
    hide: hideTaskDrawer,
    TaskDrawer,
  } = useTask({
    task: {
      ...initialValues?.task,
      plannedStartedAt: initialValues?.plannedStartedAt && dayjs(initialValues?.plannedStartedAt),
      taskSupplies: initialValues?.task?.taskSupplies?.map((taskSupply) => ({
        ...taskSupply,
        supply: {
          label: taskSupply?.supply?.name,
          value: taskSupply?.supply?.id,
        },
      })),
    },
    readOnly: true,
  })

  return (
    <div>
      <Form
        layout="vertical"
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                if (employeeMode) {
                  return (
                    <Form.Item label={"Công việc"} name={["task", "name"]}>
                      <Input disabled size={"large"} />
                    </Form.Item>
                  )
                }

                return (
                  <Form.Item label={"Công việc"} name={["task", "name"]}>
                    <Button onClick={showTaskDrawer} type="link">
                      {getFieldValue("task")?.name}
                    </Button>
                  </Form.Item>
                )
              }}
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Người xử lý"} name="assignedTo">
              <UserSelect {...(employeeMode && { size: "large", disabled: true })} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Ngày thực hiện"} name="startedAt">
              <DatePicker
                disabled={employeeMode && !isInCharge}
                className="w-full"
                {...(employeeMode && { size: "large" })}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Xác nhận hoàn tất vào lúc"} name="completedAt">
              <DatePicker
                disabled={employeeMode && !isInCharge}
                className="w-full"
                {...(employeeMode && { size: "large" })}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Ghi chú (Nếu có)"} name="note">
              <CustomEditor disabled={employeeMode && !isInCharge} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Vật tư sử dụng"}>
              <div>
                <Form.List name="stageTaskSupplies">
                  {(fields, { add, remove }) => (
                    <div
                      className={`${fields.length > 0 ? (employeeMode ? "p-2" : "border p-4 shadow-lg") : ""}`}
                    >
                      {fields.map(({ key, name, ...restFields }, index) => (
                        <Row key={key} gutter={[16, 8]} className="mb-[16px] w-full">
                          {index > 0 && (
                            <Col span={24}>
                              <Divider />
                            </Col>
                          )}
                          <Col xs={12} xl={8}>
                            <Form.Item
                              {...restFields}
                              label={`Sản phẩm vật tư ${name + 1}`}
                              name={[name, "supply"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng chọn sản phẩm vật tư",
                                },
                              ]}
                            >
                              <SupplySelect
                                disabled={employeeMode && !isInCharge}
                                allowClear={false}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restFields}
                              label={`Liều lượng sử dụng`}
                              name={[name, "quantity"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng nhập liều lượng sử dụng",
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                className="w-full"
                                placeholder="Liều lượng sử dụng"
                                disabled={employeeMode && !isInCharge}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12} xl={8}>
                            <Form.Item
                              {...restFields}
                              label={`Số cây áp dụng`}
                              name={[name, "productAmount"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng nhập số cây áp dụng",
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                className="w-full"
                                placeholder="Số cây áp dụng"
                                disabled={employeeMode && !isInCharge}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restFields}
                              label={`Đơn vị tính`}
                              name={[name, "unit"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng chọn đơn vị tính",
                                },
                              ]}
                            >
                              <AutoComplete
                                disabled={employeeMode && !isInCharge}
                                options={PRODUCT_UNIT.map((unit) => ({ label: unit, value: unit }))}
                              >
                                <Input
                                  disabled={employeeMode && !isInCharge}
                                  placeholder="Đơn vị tính"
                                />
                              </AutoComplete>
                            </Form.Item>
                          </Col>
                          <Col xs={23} xl={7}>
                            <Form.Item
                              {...restFields}
                              label={`Đơn giá`}
                              name={[name, "price"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng nhập đơn giá",
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                className="w-full"
                                placeholder="Đơn giá"
                                disabled={employeeMode && !isInCharge}
                              />
                            </Form.Item>

                            <Form.Item {...restFields} label={`Ghi chú`} name={[name, "note"]}>
                              <Input.TextArea
                                disabled={employeeMode && !isInCharge}
                                placeholder="Ghi chú"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={1} className="flex items-center">
                            <MinusCircleOutlined
                              className="cursor-pointer"
                              onClick={() => !(employeeMode && !isInCharge) && remove(name)}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Button
                        onClick={() => {
                          if (
                            last(watchStageTaskSupplies)?.supply ||
                            !watchStageTaskSupplies ||
                            watchStageTaskSupplies?.length === 0
                          ) {
                            add()
                          }
                        }}
                        disabled={employeeMode && !isInCharge}
                      >
                        Thêm vật tư nông nghiệp
                      </Button>
                    </div>
                  )}
                </Form.List>
              </div>
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Hình ảnh thêm"} name="imageUrls">
              <Upload disabled={employeeMode && !isInCharge} multiple />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          {!employeeMode && (
            <div>
              <Button loading={loading} type={"primary"} htmlType={"submit"}>
                Lưu
              </Button>
              <Button
                onClick={onCancel}
                disabled={loading}
                type={"outline"}
                style={{ marginLeft: 16 }}
              >
                Hủy
              </Button>
            </div>
          )}
          {employeeMode && (
            <Button
              disabled={!isInCharge}
              type="primary"
              size="large"
              block
              loading={loading}
              htmlType={"submit"}
            >
              Cập nhật
            </Button>
          )}
        </Row>
      </Form>
      <TaskDrawer />
    </div>
  )
}

export default StageTaskForm
